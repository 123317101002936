import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cookies } from "react-cookie-consent";
import axios from "axios";
import { configuration } from "../helpers/config";
import Head from "next/head";
import HomeBanners from "./homebanners";
import Image from "next/image";
import FindPerfectSuzuki from "./find-suzuki/findPerfectSuzuki";
import { Constants } from "../helpers/constants";
import HomeCmsLinks from "./homeCmsLinks";
import TestimonialSliderContainer from "./testimonial/TestimonialSliderContainer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Homepage = (props) => {
  const [showRecentBanner, setShowRecentBanner] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [isLoadingMobile, setIsLoadingMobile] = useState(true);
  const [recentBannerUrl, setRecentBannerUrl] = useState("");
  const initialState = {
    titleColor: "",
    titleFontSize: "",
    descColor: "",
    descFontSize: "",
    ctaColor: "",
    ctaFontSize: "",
  };
  const [banner1ThemeData, setBanner1ThemeData] = useState(initialState);
  const [banner2ThemeData, setBanner2ThemeData] = useState(initialState);
  const [banner3ThemeData, setBanner3ThemeData] = useState(initialState);

  //For managing scroll behaviour
  useEffect(() => {
    if (typeof window !== "undefined") {
      const element = document.getElementById(window.location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  useEffect(() => {
    // Retrieve the value of 'visitorunino' cookie
    const visitoruninoValue = Cookies.get("visitorunino");

    if (visitoruninoValue) {
      // Decode the Base64-encoded value
      const decodedValue = atob(visitoruninoValue);

      // Split the decoded value to extract the unique ID and model code
      const [uniqueId, modelCode] = decodedValue?.split("_");

      modelCode && getModelBanner(modelCode);
    }
  }, []);
  const LOB_VALUE = useSelector((state) => state?.app?.LobValue);
  //fetch data of model that is to be shown from cookie if user already visited that brand page.
  const getModelBanner = async (slug) => {
     // Validate inputs
     if (!slug) {
      console.log("Slug is required but not provided.");
      return;
    }
  
    if (!LOB_VALUE) {
      console.log("LOB_VALUE is null or undefined.");
      return;
    }
    try {warn
    const { data } = await axios.get(
      `${configuration.APP_URL_PRODUCTS}model/${slug}?lob=${LOB_VALUE}`,
    );
     // Check if the response contains valid banner data
     if (data?.data?.images?.desk_banners?.length > 0) {
      setShowRecentBanner(true);
      setRecentBannerUrl(data?.data?.images?.desk_banners[0]);
    }
  } catch (error) {
    // Handle API errors
    if (error?.response) {
      // Server responded with an error status
      console.log("API Error:", error.response.data);
      console.log("Status Code:", error.response.status);
    } else if (error?.request) {
      // No response received
      console.log("No response received from API:", error.request);
    } else {
      // Error setting up the request
      console.log("Request Error:", error?.message);
    }
     
    }
  };

  useEffect(() => {
    // fetch theme data
    if (LOB_VALUE) {
      getThemeData();
    } 
    
  }, [LOB_VALUE]);

  const getThemeData = async () => {
    try{
      if(LOB_VALUE!==null){
    // fetching theme data from settings api
    const { data } = await axios.get(
      `${configuration.APP_URL_CONTENTS}/settings?lob=${LOB_VALUE}`,
    );
    // assigning theme data
    if (data?.length > 0) {
      const themeData = data
        .find((x) => x.lob == LOB_VALUE)
        ?.theme_settings?.find((x) => x?.applied === true);
        if (themeData) {
      setBanner1ThemeData({
        ...banner1ThemeData,
        titleColor: themeData?.homepage_banner1_title_color,
        titleFontSize: themeData?.homepage_banner1_title_font_size,
        descColor: themeData?.homepage_banner1_desc_color,
        descFontSize: themeData?.homepage_banner1_desc_font_size,
        ctaColor: themeData?.homepage_banner1_cta_color,
        ctaFontSize: themeData?.homepage_banner1_cta_font_size,
      });
      setBanner2ThemeData({
        ...banner2ThemeData,
        titleColor: themeData?.homepage_banner2_title_color,
        titleFontSize: themeData?.homepage_banner2_title_font_size,
        descColor: themeData?.homepage_banner2_desc_color,
        descFontSize: themeData?.homepage_banner2_desc_font_size,
        ctaColor: themeData?.homepage_banner2_cta_color,
        ctaFontSize: themeData?.homepage_banner2_cta_font_size,
      });
      setBanner3ThemeData({
        ...banner3ThemeData,
        titleColor: themeData?.homepage_banner3_title_color,
        titleFontSize: themeData?.homepage_banner3_title_font_size,
        descColor: themeData?.homepage_banner3_desc_color,
        descFontSize: themeData?.homepage_banner3_desc_font_size,
        ctaColor: themeData?.homepage_banner3_cta_color,
        ctaFontSize: themeData?.homepage_banner3_cta_font_size,
      });
        }
         else {
          console.warn("No applied theme settings found for the provided LOB_VALUE.");
        }
      } else {
          console.warn("No theme data returned from API.");
     }
    }
  }
    catch (error) {
      if (error.response) {
        console.log("API Error:", error.response.data);
        console.log("Status Code:", error.response.status);
      } else if (error.request) {
        console.log("No response received from API:", error.request);
      } else {
        console.log("Error in API request:", error.message);
        }
    }
  };

  return (
    <>
      <Head>
        <title>{props?.data && props?.data[0]?.seo_settings?.meta_title}</title>
        <meta
          name="description"
          content={props?.data && props?.data[0]?.seo_settings?.meta_desc}
        />
        <meta
          name="keywords"
          content={props?.data && props?.data[0]?.seo_settings?.keywords}
        />
        <link rel="icon" href={`${configuration.IMG_STATIC_URL}favicon.ico`}  type="image/x-icon" />
<link rel="shortcut icon" href={`${configuration.IMG_STATIC_URL}favicon.ico`}  type="image/x-icon" />
        {(process.env.NEXT_PUBLIC_DOMAIN_NAME.includes('uat') || process.env.NEXT_PUBLIC_DOMAIN_NAME.includes('staging'))?
        <>
        <meta name="robots" content="noindex, nofollow" />
        </>:""}
                            {/* MEXICO SEO META OG TAG STARTS */}
    {process.env.NEXT_PUBLIC_COUNTRY_CODE=="MEX"?
    <>
    <meta property="og:title" content=" Suzuki Autos México: Sitio oficial" />
<meta property="og:type" content="website" />
<meta property="og:url" content=" https://autos.suzuki.com.mx/ " />
<meta property="og:site_name" content=" Suzuki México" />
<meta property="og:description" content=" Suzuki México: Descubre los autos de Suzuki que incluyen sedán, hatchback, MUV, SUV y vehículos familiares en México. Agenda una prueba de manejo ahora, localiza tu distribuidor más cercano, descarga folletos."></meta>
    </>:""}
    {/* MEXICO SEO META OG TAG ENDS */}
                        {/* ro SEO META OG TAG STARTS */}
                        {process.env.NEXT_PUBLIC_COUNTRY_CODE=="RO"?
    <>
{/* <meta property="og:title" content="Suzuki- Suzuki Romania | Site-ul oficial" />
<meta property="og:site_name" content="Suzuki Romania"/>
<meta property="og:url" content="https://auto.suzuki.ro"/>
<meta property="og:description" content="Explorează cele mai recente mașini Suzuki pentru nevoile tale!" />
<meta property="og:type" content="website" /> */}
<meta property="og:title" content="Suzuki Romania - Site Oficial Auto"/>
<meta property="og:type" content="website"/>
<meta property="og:url" content="https://auto.suzuki.ro/"/>
<meta property="og:site_name" content="Suzuki Romania"/>
<meta property="og:description" content="Explorați site-ul oficial Suzuki România pentru cele mai recente modele, inclusiv e-Vitara, Swift Hybrid, Vitara Hybrid, S-Cross Hybrid și Swace Hybrid. Verificați prețurile, ofertele și detaliile de performanță."/>

    </>:""}
    {/* ro SEO META OG TAG ENDS */}

     {/* VNM SEO META OG TAG STARTS */}

    {configuration?.COUNTRY_CODE == "VNM" ? (
          <>
         <meta property="og:title" content="Việt Nam Suzuki | Trang Thông Tin Chính Thức"/>
          <meta property="og:type" content="website"/>
          <meta property="og:url" content="https://www.suzuki.com.vn/" />
          <meta property="og:site_name" content="Việt Nam Suzuki"></meta>
          <meta property="og:description" content=" Việt Nam Suzuki: Khám phá xe ô tô Suzuki, bao gồm Sedan, Hatchback, MPV, SUV và xe thương mại tại Việt Nam. Đặt lịch lái thử, tìm kiếm đại lý gần nhất, tải xuống tài liệu quảng cáo"/>
          </>
        ) : (
          ""
        )}
     {/* VNM SEO META OG TAG ENDS */}
    
      </Head>

      {props.data ? (
        <>
          {/* Top Banner section  */}
          {props.data[0]?.banners && (
            <HomeBanners
              banners={props.data[0]?.banners}
              banner1ThemeData={banner1ThemeData}
              banner2ThemeData={banner2ThemeData}
              banner3ThemeData={banner3ThemeData}
            />
          )}
          {/*show recent banner if user already visited a brand page*/}
          {showRecentBanner && (
            <div>
              <div className="mt-10">
                 {/* {isLoadingMobile && !hideImage && <Skeleton height={1080} width={1920} />} */}
                {!hideImage && (
                <Image
                  src={ recentBannerUrl? `${configuration.IMG_URL}${recentBannerUrl}` : "" }
                  height={!isLoadingMobile?1080:0}
                  width={!isLoadingMobile?1920:0}
                  alt=""
                  // className={"w-full"}
                  onError={() => setHideImage(true)}
                  className={`w-full ${isLoadingMobile ? "hidden" : "block"}`}
                  onLoad={() => setIsLoadingMobile(false)}
                />
                )}
              </div>
            </div>
          )}
          {/* find your perfect suzuki section */}
          
          <div id="find-perfect-suzuki">
            {props.data[0]?.cars && (
              <FindPerfectSuzuki
                sectionHeading={Constants.homePage.HOMEPAGE_SUZUKI}
                // cars={props.data[0]?.cars}
                carsData={props.data[0]?.cars}
                modelsData={props?.modeldata}
              />
            )}
          </div>

          {/* cms pages interlinking cards */}
          {props.data[0]?.placeholder && (
            <HomeCmsLinks cmspages={props.data[0]?.placeholder} />
          )}
          <div>
            <TestimonialSliderContainer pageType="homepage"/>
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-screen">
          {/* Data not found Text if data not available from API */}
          <h1>{Constants.generic.DATA_NOT_FOUND}</h1>
        </div>
      )}
    </>
  );
};

export default Homepage;
